import { combineReducers } from '@reduxjs/toolkit';
import app from './app';
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    ...asyncReducers,
    app,
  });
  // const persistConfig = {
  //   key: 'root',
  //   storage
  // };

  //   const persistedReducer = (state, action) => persistReducer(persistConfig, combinedReducer(state, action));

  /*
  Reset the redux store when user logged out
   */
  if (action.type === 'auth/user/userLoggedOut') {
    // state = undefined;
  }

  return combinedReducer(state, action);
  // return persistedReducer(state, action);
};

export default createReducer;
