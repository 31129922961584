import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { StorageKeys } from "~/Configs/Storage";
// import { getStorage, getUserPermissions, userHavePermission } from "~/Helpers";
// import { isEmpty } from "~/Helpers/isEmpty";
import { useUserContext } from "~/Services/Providers";
import Layout from "~/Components/Layout";

function PublicRoute1({ children, ...rest }) {

  return <Outlet />
}

export default PublicRoute1;
