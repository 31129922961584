import React from 'react';

const ItemLoader = ({ classNameContainer, classNameItem }) => {
  let itemCounter = [1, 2, 3, 4, 5, 6];

  return (
    <div className={classNameContainer}>
      <div className={classNameItem}>
        {itemCounter.map((item) => (
          <label key={item}>●</label>
        ))}
      </div>
    </div>
  );
};

export default ItemLoader;
