import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux'
import { toJS } from 'mobx'

const initialState = {
    data: {},
    token: "",
    isAuthenticated: false,
    role: [],
    code: 0,
    doctor: [],
    isLoading: "idle"
}

const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        changeUser: (state, action) => {
            state.data = !!action?.payload?.data ? action?.payload?.data : state.data;
            state.token = !!action?.payload?.token ? action?.payload?.token : action?.payload?.token === "" ? action?.payload?.token : state.token;
            state.isAuthenticated = !!action?.payload?.isAuthenticated ? action?.payload?.isAuthenticated : state.isAuthenticated;
            state.role = !!action?.payload?.role ? action?.payload?.role : state.role;
            state.code = !!action?.payload?.code ? action?.payload?.code : state.code;
            state.doctor = !!action?.payload?.doctor ? action?.payload?.doctor : state.doctor;
        },
        changeUserRole: (state, action) => {
            state.role = !!action?.payload?.role ? action?.payload?.role : state.role;
            state.isLoading = "pending"
        },
        changeUserRoleIsLoading: (state, action) => {
            // if(!!)
            state.isLoading = "idle"
        },
        changeUserToInit: (state, action) => {
            state.data = {}
            state.token = ""
            state.isAuthenticated = false
            state.role = []
            state.code = 0
            state.isLoading = "idle"
        },
        reset: () => initialState
    },
});
export const {
    reset,
    changeUser,
    changeUserRole,
    changeUserToInit,
    changeUserRoleIsLoading,
} = UserSlice.actions;

export default UserSlice.reducer;
