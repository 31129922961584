import axios from "axios";
import { create } from "apisauce";
// import ApiConfigs from "~/Configs/Api";
import ApiConfigs from "../../Configs/Api";
import { request, request2URL, downloadFile } from '~/Services/Api/axios-utils'

const customAxiosInstance = axios.create({
  baseURL: ApiConfigs.apiUrl,
  timeout: ApiConfigs.timeout,
  headers: {
    // Accept: `application/json;v=${ApiConfigs.version}`,
    Accept: `application/json`,
    "Content-Type": "application/json",
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
});
const Api = create({ axiosInstance: customAxiosInstance });

// const Api = create({
//   baseURL: ApiConfigs.apiUrl,
//   timeout: ApiConfigs.timeout,
//   headers: {
//     Accept: `application/json;v=${ApiConfigs.version}`,
//     "Content-Type": "application/json",
//     // 'Content-Type': 'application/x-www-form-urlencoded',
//   },
// });

const CancelToken = axios.CancelToken;
const requestsSourceCancellation = CancelToken.source();
const endpoints = {
  // Authorization : Bearer 739|jSn9fHA0Y82vUJCPFMd6ZQp5rUTKnK2xZmaYvE7826342746
  Police: {
    Add: (data, headers) =>
      request({
        url: `/admin/change-policy`, method: 'post', params: { ...data?.params }, data: { ...data.body }, headers: { ...headers }
      }),
    PrivacyPoliceGet: (data, headers) =>
      request2URL({
        url: `/static/privacy-policy`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
    TearmOfUseGet: (data, headers) =>
      request2URL({
        url: `/static/terms-of-use`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
    ContactUsGet: (data, headers) =>
      request2URL({
        url: `/static/contact-us`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
    SecurityPolicyGet: (data, headers) =>
      request2URL({
        url: `/static/security-policy`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
  },
  PrivacyPolice: {
    get: (data, headers) =>
      request2URL({
        url: `/static/privacy-policy`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
  },
  topDoctors: {
    getAll: (data, headers) =>
      request({
        url: `/admin/top-doctors`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
    add: (data, headers) =>
      request({
        url: `/admin/top-doctors`, method: 'post', params: { ...data?.params }, data: { ...data.body }, headers: { ...headers }
      }),
    edit: (data, headers) =>
      request({
        url: `/admin/top-doctors/${data.id}`, method: 'put', params: { ...data?.params }, data: { ...data.body }, headers: { ...headers }
      }),
    delete: (data, headers) =>
      request({
        url: `/admin/top-doctors/${data.id}`, method: 'delete', headers: { ...headers }
      }),
  },
  hospital: {
    getAll: (data, headers) =>
      request({
        url: `/admin/hospital`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
    add: (data, headers) =>
      request({
        url: `/admin/hospital`, method: 'post', params: { ...data?.params }, data: { ...data.body }, headers: { ...headers }
      }),
    edit: (data, headers) =>
      request({
        url: `/admin/hospital/${data.id}`, method: 'put', params: { ...data?.params }, data: { ...data.body }, headers: { ...headers }
      }),
    delete: (data, headers) =>
      request({
        url: `/admin/hospital/${data.id}`, method: 'delete', headers: { ...headers }
      }),
  },
  BeautyCenter: {
    getAll: (data, headers) =>
      request({
        url: `/admin/beauty-center`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
    getById: (data, headers) =>
      request({
        url: `/admin/beauty-center/${data?.id}`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
    add: (data, headers) =>
      request({
        url: `/admin/beauty-center`, method: 'post', params: { ...data?.params }, data: { ...data.body }, headers: { ...headers }
      }),
    delete: (data, headers) =>
      request({
        url: `/admin/beauty-center/${data.id}`, method: 'delete', headers: { ...headers }
      }),
    edit: (data, headers) =>
      request({
        url: `/admin/beauty-center/${data?.id}`, method: 'put', params: { ...data?.params }, data: { ...data.body }, headers: { ...headers }
      }),
    addAvatar: (data, headers) =>
      request({
        url: `/admin/beauty-center-images/${data?.id}`, method: 'post', params: { ...data?.params }, data: data.body, headers: { ...headers, 'content-type': 'multipart/form-data', }
      }),
    addService: (data, headers) =>
      request({
        url: `/admin/beauty-center-services`, method: 'post', params: { ...data?.params }, data: data.body, headers: { ...headers, 'content-type': 'multipart/form-data', }
      }),
    editService: (data, headers) =>
      request({
        url: `/admin/beauty-center-services/${data?.id}?_method=PUT`, method: 'post', params: { ...data?.params }, data: data.body, headers: { ...headers, 'content-type': 'multipart/form-data', }
      }),
    deleteService: (data, headers) =>
      request({
        url: `/admin/beauty-center-services/${data.id}`, method: 'delete', headers: { ...headers }
      }),
    addBranche: (data, headers) =>
      request({
        url: `/admin/beauty-center-branches`, method: 'post', params: { ...data?.params }, data: { ...data.body }, headers: { ...headers, 'content-type': 'multipart/form-data', }
      }),
    editBranche: (data, headers) =>
      request({
        url: `/admin/beauty-center-branches/${data?.id}`, method: 'put', params: { ...data?.params }, data: { ...data.body }, headers: { ...headers }
      }),
    deleteBranche: (data, headers) =>
      request({
        url: `/admin/beauty-center-branches/${data.id}`, method: 'delete', headers: { ...headers }
      }),
  },
  static: {
    city: (data, headers) =>
      request2URL({
        url: `/static/cities`, method: 'get', params: { ...data?.params }, headers: { ...headers }//change url tomwor
      }),
    country: (data, headers) =>
      request2URL({
        url: `/static/countries`, method: 'get', headers: { ...headers }//change url tomwor
      }),
  },
  patient: {
    all: (data, headers) =>
      request({
        url: `/admin/patients`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
    suspend: (data, headers) =>
      request({
        url: '/admin/patients/account/suspend', method: 'post', data: { ...data }, headers: { ...headers }
      }),
    activate: (data, headers) =>
      request({
        url: '/admin/patients/account/activate', method: 'post', data: { ...data }, headers: { ...headers }
      }),
  },
  profits: {
    profits: (data, headers) =>
      request({
        url: `/admin/reports/finicial`, method: 'get', headers: { ...headers }
      }),
  },
  user: {
    me: (data, headers) =>
      request({
        url: `/admin/me`, method: 'get', headers: { ...headers }
      }),
    insuranceCompany: (data, headers) =>
      request({
        url: `/admin/insurance-company`, method: 'get', headers: { ...headers }
      }),
    doctors: (data, headers) =>
      request({
        url: `/admin/doctors`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
    login: (data, headers) =>
      request({
        url: '/login', method: 'post', data: { ...data }, headers: { ...headers }
      }),
    privileges: (data, headers) =>
      request({
        url: '/admin/privileges', method: 'get', data: { ...data }, headers: { ...headers }
      }),
    activityLgos: (data, headers) =>
      request({
        url: '/admin/activity-log', method: 'get', data: { ...data }, headers: { ...headers }
      }),
  },
  doctors: {
    getStatistics: (data, headers) =>
      request({
        url: `/admin/reports/doctors`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
    getSubscriptions: (data, headers) =>
      request({
        url: `/admin/doctors/subscriptions/${data.id}`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
    renewPackage: (data, headers) =>
      request({
        url: '/admin/subscription-renew', method: 'post', data: { ...data }, headers: { ...headers }
      }),
    BlockConsoulting: (data, headers) =>
      request({
        url: '/admin/doctors/consulting/block', method: 'post', data: { ...data }, headers: { ...headers }
      }),
    suspend: (data, headers) =>
      request({
        url: '/admin/doctors/account/suspend', method: 'post', data: { ...data }, headers: { ...headers }
      }),
    activate: (data, headers) =>
      request({
        url: '/admin/doctors/account/activate', method: 'post', data: { ...data }, headers: { ...headers }
      }),
    trust: (data, headers) =>
      request({
        url: '/admin/doctors/account/trust', method: 'post', data: { ...data }, headers: { ...headers }
      }),
  },
  notification: {
    sendDoctorAndPatient: (data, headers) =>
      request({
        url: '/admin/send-doctor-notification', method: 'post', data: { ...data.body }, headers: { ...headers }
      }),
    sendForAllDoctors: (data, headers) =>
      request({
        url: '/admin/notify-all-doctor', method: 'post', data: { ...data.body }, headers: { ...headers }
      }),
    sendForAllPatient: (data, headers) =>
      request({
        url: '/admin/notify-all-patient', method: 'post', data: { ...data.body }, headers: { ...headers }
      }),
  },
  feedbacks: {
    getAll: (data, headers) =>
      request({
        url: `/admin/feedbacks`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
    showFeedback: (data, headers) =>
      request({
        url: `/admin/feedbacks/show`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
    showFeedbacks: (data, headers) =>
      request({
        url: `/admin/feedbacks/show-multi`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
    delete: (data, headers) =>
      request({
        url: `/admin/feedbacks`, method: 'delete', data: { ...data }, headers: { ...headers }
      }),
  },
  packages: {
    getAll: (data, headers) =>
      request({
        url: `/admin/packages`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
    add: (data, headers) =>
      request({
        url: `/admin/packages`, method: 'post', data: { ...data.body }, headers: { ...headers }
      }),
    disablePackage: (data, headers) =>
      request({
        url: `/admin/packages/disable/${data.id}`, method: 'post', data: { ...data.body }, headers: { ...headers }
      }),
    disablePlan: (data, headers) =>
      request({
        url: `/admin/plans/disable/${data.id}`, method: 'post', data: { ...data.body }, headers: { ...headers }
      }),
    getPlans: (data, headers) =>
      request({
        url: `/admin/plans/${data.id}`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
    addPlan: (data, headers) =>
      request({
        url: `/admin/plans`, method: 'post', data: { ...data.body }, headers: { ...headers }
      }),
    add_subsicription_for_doctor: (data, headers) =>
      request({
        url: `/admin/add-subscription`, method: 'post', data: { ...data.body }, headers: { ...headers }
      }),
  },
  Bank: {
    Excel: (data, headers) =>
      request({
        url: `/admin/accounts`, method: 'get', params: { ...data?.params }, headers: { ...headers }
      }),
  }
};

export default endpoints;
