import React, { useState, useEffect } from "react";
// import { withStyles } from "@mui/styles";
// import classNames from "classnames";
// import { decorate, observable } from "mobx";
// import { observer } from "mobx-react";
import PropTypes from "prop-types";
// import useStyles from "./styles";
import { Hidden, IconButton, ListItem, ListItemText, ListItemButton, ListItemIcon } from "@mui/material";
// import withMediaQuery from "~/Services/Helpers/WithMediaQuery";
import { useAppContext, } from "~/Services/Providers";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { StorageKeys } from "~/Configs/Storage";
import { CustomLayout, CustomMain } from './styles'
import Header from './Partials/header';
import Footer from './Partials/Footer';
import BasicBreadcrumbs from "../BasicBreadcrumbs";
import Breadcrumbs from "../Breadcrumbs";
import SubHeader from "./Partials/SubHeader";


// let dataObserver = observable({
//   ser: [],
//   EXH: [],
//   SYR: []
// });

const Layout = ({ children }) => {
  const navigate = useNavigate();

  let user = JSON.parse(window.localStorage.getItem(StorageKeys.userContext));

  let [open, setopen] = useState(false);

  const handleDrawerToggle = (openVal, expanded = true) => {
    if (expanded) setopen(openVal);
    else setopen(false);
  };

  const theme = useTheme();

  const { appContextObj } = useAppContext();
  // const classes = useStyles();
  const breadcrumbs = [
    { lable: "Home", link: "/" },
  ]

  return (
    <CustomLayout>
      <Header />
      <CustomMain>
        {/* <BasicBreadcrumbs breadcrumbs={breadcrumbs} /> */}
        {/* <Breadcrumbs /> */}
        <SubHeader />
        {children}
      </CustomMain>
      <Footer />
    </CustomLayout>
  );
}


// Layout.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

// decorate(Layout, {
//   open: observable,
// });

// export default withMediaQuery("(max-width:1316px)")(withStyles(styles, { withTheme: true })(observer(Layout)));
// export default observer(Layout);
export default Layout;
