import React from 'react'
import { CustomFooter } from './styles'

const Footer = () => {
    return (
        <CustomFooter>
            <a>ewfe</a>
        </CustomFooter>
    )
}

export default Footer