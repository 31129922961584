import React, { Suspense, useEffect, useState, useContext, useMemo, useCallback } from "react";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import Layout from "../Components/Layout";
import GeneralLoader from "../Components/UI/GeneralLoader";
import { RoutesRenderer } from "./RouterRenderer";
import { authRoutes, privateRoutes, publicRoutes, } from "./RouteList";
import { useAppContext, useUserContext } from "~/Services/Providers";
import { StorageKeys } from "../Configs/Storage";
import { PrivateRoute, AuthRoute, PublicRoute } from "../Components/CustomRoutes/Partials";

export default function AppRoutes() {
  const { setAppContext, appContextObj } = useAppContext();
  const { setUserContext, userContextObj } = useUserContext();
  let app = JSON.parse(window.localStorage.getItem(StorageKeys.appContext));

  useEffect(() => {
    let user = JSON.parse(window.localStorage.getItem(StorageKeys.userContext));
    const data = user?.data ? user?.data : userContextObj.data;
    const isAuthenticated = user?.isAuthenticated ? user?.isAuthenticated : userContextObj.isAuthenticated;
    const token = user?.token ? user?.token : userContextObj?.token;
    // const role = user?.role ? user?.role : []
    const role = [{ permissions: [{ name: "iran" }] }]
    setUserContext({
      data,
      token,
      isAuthenticated,
      role,
    });
    const theme = app?.theme ? app?.theme : "light";
    const locale = app?.locale ? app?.locale : "en";
    const direction = app?.direction ? app?.direction : "ltr";
    setAppContext({ theme, locale, direction });
  }, [])

  return (
    <Suspense fallback={<GeneralLoader />}>
      <Routes>
        <Route>
          {RoutesRenderer(authRoutes, AuthRoute)}
        </Route>

        {/* <Route >
            <Route
              element={
                <Layout>
                  <Outlet />
                </Layout>
              }
            >
              {RoutesRenderer(publicRoutes, PublicRoute)}
            </Route>
          </Route> */}

        <Route >
          <Route
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          >
            {RoutesRenderer(privateRoutes, PrivateRoute)}
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}
// }
//   return (
//     <Suspense fallback={<GeneralLoader />}>
//       <Routes>
//         <Route path="auth" element={<AuthRoute />}>
//           {RoutesRenderer(authRoutes)}
//         </Route>
//         <Route path="/">
//           <Route element={<Layout />}>
//             {RoutesRenderer(publicRoutes)}
//             <Route element={<PrivateRoute />}>
//               {RoutesRenderer(privateRoutes)}
//             </Route>
//           </Route>
//         </Route>
//       </Routes>
//     </Suspense>
//   );
// }
