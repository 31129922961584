import { isEmpty } from "~/Services/Helpers/isEmpty";

const Seconds = (n) => n * 1000;

const baseURL = !isEmpty(process.env.PUBLIC_URL)
    ? process.env.PUBLIC_URL
    : `http://promedapp.sy/dashboard/`;

export const getURL = (path) =>
    !isEmpty(process.env.REACT_APP_BACKEND_BASE_URL)
        ? `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}/`
        : `https://admin-45.promedapp.com/api`;
// : `https://admin-45.promedapp.sy/api`;
// : `http://promedapp.sy/dashboard/api`;

export default {
    baseURL,
    apiUrl: getURL("api"),
    timeout: Seconds(100),
    clientToken: "SECRET_TOKEN",
    version: "1.0.0",
    email: "promed@promedapp.com",
    phone: "+(963)984506010",
    storageUrl: `https://admin-45.promedapp.sy/`,
    multipleMediaFileUpload: "admin/file-upload",
    multipleMediaImageUpload: `https://admin-45.promedapp.sy/`,
};
