import React, { useState, createContext, useContext } from 'react';
import { StorageKeys } from '~/Configs/Storage';

const AppContextData = {
  theme: 'light', // dark , light
  locale: 'en', // en , ar , fr , ir .....
  direction: 'ltr', //ltr , rtl
  langs: {
    ar: {
      text: ' العربية ',
      // icon: <Flag  height="10" code="sau" />,
      code: 'ar',
    },
    en: {
      text: 'English',
      //  icon: <Flag height="10" code="gb" />,
      code: 'en',
    },
    en: {
      text: 'Iran',
      //  icon: <Flag height="10" code="gb" />,
      code: 'ir',
    },
  },
};

// initializing context
export const AppContext = createContext(AppContextData);

// setting a helper function to use context
export const useAppContext = () => useContext(AppContext);

export function AppContextProvider({ children }) {
  const [appContextObj, setAppContext] = useState(AppContextData);

  const setContext = (data) => {
    setAppContext((currContext) => ({ ...currContext, ...data }));
    window.localStorage.setItem(
      StorageKeys.appContext,
      JSON.stringify({ ...appContextObj, ...data })
    );
  };

  return (
    <AppContext.Provider value={{ appContextObj, setAppContext: setContext }}>
      {children}
    </AppContext.Provider>
  );
}
