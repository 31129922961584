import React from 'react';
import { ReactComponent as ErrorPhoto } from '~/Assets/error.svg';
import useStyles from './styles';
import { Button } from '@mui/material';
const AppCrashedComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ErrorPhoto height="50%" width="50%" />
      <h2>Oops !! ... Something went wrong</h2>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          window.location = '/';
        }}
      >
        Click Here to go home
      </Button>
    </div>
  );
};

export default AppCrashedComponent;
