import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { StorageKeys } from "~/Configs/Storage";
// import { getStorage } from "~/Helpers";
// import { isEmpty } from "~/Helpers/isEmpty";
// import { getUserPermissions, userHavePermission } from "~/Helpers";
// import { useUserContext } from "~/Services/Providers";

function AuthRoute(rest) {
  const user = useSelector((state) => state.app.user)

  // const { userContextObj } = useUserContext();
  // const permissions = !isEmpty(user?.role)
  //   ? getUserPermissions(user?.role)
  //   : [];
  // let ItemPermissions = Array.isArray(rest?.permission)
  //   ? rest?.permission
  //   : [rest?.permission];

  return !!user?.token ? (
    <Navigate to="/doctors" exact />
  ) : (
    <Outlet />
  );
}

export default AuthRoute;
