import './App.css';
import DataTable from './DataTable'
import { StyledEngineProvider } from '@mui/material/styles';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { I18nProvider } from "./Services/i18n";
import { AppContextProvider, NotificationContextProvider, ThemeContextProvider, UserContextProvider } from "./Services/Providers";
import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import Provider from 'react-redux/es/components/Provider';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import store from './store';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import GeneralLoader from './Components/UI/GeneralLoader';
import { styled } from '@mui/material/styles'
import ErrorBoundary from "~/Components/ErrorBoundary";

function App() {
  let persistor = persistStore(store);
  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (data, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(data, { variant });
  };

  // const queryClient = new QueryClient()
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (err) => {
        !err?.statusText && !!err?.response && handleClickVariant(`${err?.response?.data?.message}`, 'warning')
        !err?.statusText && !err?.response && handleClickVariant(`${err?.message}`, 'error')
      }
    }),
  })

  const StyledSnackbarProvider = styled(SnackbarProvider)(({ theme }) => ({
    '&.notistack-MuiContent-warning': {
      backgroundColor: '#E96102',
      color: "#ffffff"
    },
  }));

  return (
    // <ErrorBoundary>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <UserContextProvider>
            <AppContextProvider>
              <I18nProvider>
                <ThemeContextProvider>
                  {/* <StyledSnackbarProvider
                    maxSnack={10}
                    autoHideDuration={5000}
                    dense={true}
                    disableWindowBlurListener={true}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  > */}
                  <PersistGate loading={<GeneralLoader />} persistor={persistor}>
                    <Router>
                      <Routes />
                    </Router>
                    <ReactQueryDevtools initialIsOpen={false} />
                  </PersistGate>
                  {/* <CustomPaginationActionsTable /> */}
                  {/* </StyledSnackbarProvider> */}
                  {/* <Notification /> */}
                </ThemeContextProvider>
              </I18nProvider>
            </AppContextProvider>
          </UserContextProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </Provider>
    // {/* </ErrorBoundary> */}
  );
}

export default App;
