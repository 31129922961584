import axios from 'axios'
import ApiConfigs from "../../Configs/Api";

const client = axios.create({
    baseURL: ApiConfigs.apiUrl,
    timeout: ApiConfigs.timeout,
    headers: {
        Accept: `application/json;v=${ApiConfigs.version}`,
        "Content-Type": "application/json",
        "version": ApiConfigs.version
        // 'Content-Type': 'application/x-www-form-urlencoded',
    },
})

export const request = ({ ...options }) => {
    // client.defaults.headers.common.Authorization = `Bearer token`
    const onSuccess = response => response
    const onError = error => {
        // console.log('error', error)
        return error
    }

    // return client(options).then(response => {
    // console.log('response', response.data)
    // return response.data
    // })
    return client(options)
}

const client2URL = axios.create({
    // baseURL: "https://promedapp.sy/api",
    baseURL: "https://api.promedapp.com/api",
    timeout: ApiConfigs.timeout,
    headers: {
        Accept: `application/json;v=${ApiConfigs.version}`,
        "Content-Type": "application/json",
        "version": ApiConfigs.version
        // 'Content-Type': 'application/x-www-form-urlencoded',
    },
})

export const request2URL = ({ ...options }) => {
    // client.defaults.headers.common.Authorization = `Bearer token`
    const onSuccess = response => response
    const onError = error => {
        // console.log('error', error)
        return error
    }

    // return client(options).then(response => {
    // console.log('response', response.data)
    // return response.data
    // })
    return client2URL(options)
}