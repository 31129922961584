import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useAppContext } from "./AppContextProvider";
// import rtlPlugin from "stylis-plugin-rtl";
// import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
// import createCache from "@emotion/cache";

// const cacheRtl = createCache({
//   key: "muirtl",
//   stylisPlugins: [prefixer, rtlPlugin],
// });
// const cacheLtr = createCache({
//   key: "",
//   stylisPlugins: [prefixer, ""],
// });

export const palette = {
  primary: { main: "#0094D9", dark: "#001d2b", light: "#99d4ef" },
  secondary: { main: "#cce9f7", dark: "#cce9f7", light: "#cce9f7" },
  pending: { main: "#f29339", dark: "#cce9f7", light: "#cce9f7" },
  text: {
    main: "#000"
  },
  // inherit: {
  //   main: '#0094D9',
  // },
  // default: {
  //   main: '#0094D9'
  // },
  danger: { main: '#EB5757', dark: "#D90368", light: "#D90368" },
  light: { main: '#0094D9' },
  dark: { main: '#0094D9' },
  icon: { main: '#0094D9' },
  iconHeader: { main: '#0094D9' },
  newLight: {
    main: '#0094D9',
    secondary: '#0094D9',
    mainText: '#0094D9',
    secondaryText: '#0094D9',
    info: '#0094D9',
    footer: '#0094D9',
    footerText: '#0094D9',
  },
  newDark: {
    main: 'rgba(79, 79, 79, 0.48)',
    secondary: '#333333',
    secondary2: '#E1D2EE',
    header: '#474747',
    mainText: '#F2F2F2',
    secondaryText: '#979790',
    info: '#8233C5',
    footer: '#3F3F3F',
    footerText: 'rgba(255, 249, 253, 0.48)',
  },

  placeHolderText: {
    main: 'rgba(151, 151, 144, 0.72)',
  },
  infoBar: {
    main: '#0094D9',
  },
  primaryIcon: {
    main: '#0094D9',
  },
  whiteIcon: {
    main: '#fff',
  },
  darkIcon: {
    main: '#0094D9',
  },
  darkSubmitButton: {
    main: '#0094D9',
  },

  notificationIcon: {
    main: '#0094D9',
  },


};

export function ThemeContextProvider({ children }) {
  const { appContextObj } = useAppContext();

  const theme = createTheme({
    direction: appContextObj.direction,
    // direction: "ltr",
    locale: appContextObj.locale,
    // fontFamily: 'Cairo, sans-serif',
    fontFamily: 'Montserrat, sans-serif',
    fontSizeSM: '.8rem',
    fontSizeMD: '1rem',
    fontSizeLG2: '1.3rem',
    fontSizeLG: '1.7rem',
    fontSizeXL: '1.8rem',
    breakpoints: {
      keys: ["xs", "ss", "sm", "mm", "md", "lg", "xl"],
      values: {
        ss: 201,
        xs: 426,
        sm: 601,
        md: 767,
        lg: 900,
        lg2: 1000,
        xl: 1023,
        xlDown: 1024,
        x2l: 1249,
        x3l: 1441,
        x4l: 1700,
        x5l: 1921
      },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
    },
    palette: {
      type: appContextObj.theme,
      ...palette,
    },
  });
  // return appContextObj.direction === "rtl" ? (
  //   <CacheProvider value={cacheRtl}>
  //     <ThemeProvider theme={theme}>{children}</ThemeProvider>
  //   </CacheProvider>
  // ) : (
  //   <ThemeProvider theme={theme}>{children}</ThemeProvider>
  // );
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
