import { useAppContext } from "~/Services/Providers";
import GeneralStyles from "~/Components/From/GeneralStyles/";
import { makeStyles } from '@mui/styles'
import { styled } from "@mui/material/styles";

const openAside = 275;
const closedAside = 110;

// const useStyles = makeStyles((theme) => ({
//   root101: {
//     flexGrow: 1,
//     minHeight: "100vh",
//     zIndex: 1,
//     overflow: "hidden",
//     position: "relative",
//     //this flex is breaking the table
//     // display: 'flex',
//     direction: theme.direction,
//     fontFamily: `${theme.locale === "ir" ? "iranyekan, sans-serif" : "Almarai"
//       }, sans-serif`,
//   },
//   toolbar: {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "flex-start",
//     padding: "2px 0px",
//     ...theme.mixins.toolbar,
//     // paddingTop: "170px",
//     backgroundColor:
//       theme.palette.type === "light"
//         ? '#ffffff'
//         : '#202020',
//     [theme.breakpoints.down("600")]: {
//       // paddingTop: "145px",
//     },
//   },
//   content: {
//     flexGrow: 1,
//     fontFamily: `${theme.locale === "ir" ? "iranyekan, sans-serif" : "Almarai"
//       }, sans-serif`,
//     paddingTop: theme.spacing(2.5),
//     paddingBottom: theme.spacing(3),
//     backgroundColor:
//       theme.palette.type === "light"
//         ? '#ffffff'
//         : '#202020',
//     // paddingLeft: theme.direction == 'ltr' ? closedAside + 35 : null,
//     // paddingRight: theme.direction == 'rtl' ? closedAside + 35 : null,
//     transition: theme.transitions.create("padding", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     [theme.breakpoints.down("sm")]: {
//       paddingTop: theme.spacing(2),
//       paddingBottom: theme.spacing(2),
//     },
//     minHeight: "93.8vh",
//     // marginTop: '50px'
//     [theme.breakpoints.down("md")]: {
//       // paddingRight: `40px`,
//       // paddingLeft: `40px`,
//       marginTop: '70px'
//     },
//     display: 'flex'
//   },
//   drawerOpenContent: {
//     // paddingLeft: openAside + 35,//for open drawer
//     paddingRight: openAside + 35,//for open drawer
//     transition: theme.transitions.create("padding", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//   },
//   contentWrapper: {
//     ...GeneralStyles(theme)["contentWrapper"],
//     fontFamily: `${theme.locale === "ir" ? "iranyekan, sans-serif" : "Almarai"
//       }, sans-serif`,
//     width: '100%'
//   },
//   li: {
//     padding: '0.4rem 1.5rem',
//     borderRadius: '5px',
//     display: 'inline-block',
//     margin: '0 12px',
//     fontFamily: `${theme.locale === "ir" ? "iranyekan, sans-serif" : "Almarai"
//       }, sans-serif`,
//     '&:hover': {
//       backgroundColor: '#F46C4C',
//       color: '#fff',
//       cursor: 'pointer',
//     }
//   }
// }));

// export default useStyles;

export const CustomLayout = styled('div')(({ theme }) => ({
  flexGrow: 1,
  minHeight: "100vh",
  zIndex: 1,
  overflow: "hidden",
  position: "relative",
  //this flex is breaking the table
  // display: 'flex',
  direction: theme.direction,
  fontFamily: theme.fontFamily,
  [theme.breakpoints.up("ss")]: {
    fontSize: theme.fontSizeSM,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: theme.fontSizeMD,
  },
  [theme.breakpoints.up("x3l")]: {
    fontSize: theme.fontSizeLG2,
  },
  // [theme.breakpoints.up("x4l")]: {
  //     fontSize: theme.fontSizeLG2,
  // },
  [theme.breakpoints.up("x5l")]: {
    fontSize: theme.fontSizeXL,
  },
}));

export const CustomMain = styled('div')(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingTop: theme.spacing(8.2),
  paddingBottom: theme.spacing(3),
  // backgroundColor: "#ffffff",
  // theme.palette.type === "light"
  //   ? theme.palette.newLight.secondary
  //   : theme.palette.newDark.secondary,
  // paddingLeft: theme.direction == 'ltr' ? closedAside + 35 : null,
  // paddingRight: theme.direction == 'rtl' ? closedAside + 35 : null,
  transition: theme.transitions.create("padding", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  minHeight: "92.7vh",
  [theme.breakpoints.up('ss')]: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(3),
  },
  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(12.95),
    paddingBottom: theme.spacing(3),
    paddingLeft: '48px',
    paddingRight: '48px',
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(3),
  },
  [theme.breakpoints.up('xlDown')]: {
    paddingTop: theme.spacing(8.5),
    paddingBottom: theme.spacing(3),
  },
  [theme.breakpoints.up('xl')]: {
    paddingTop: theme.spacing(8.8),
    paddingBottom: theme.spacing(3),
  },
  // [theme.breakpoints.up('x3l')]: {
  //     paddingTop: theme.spacing(13.5),
  //     paddingBottom: theme.spacing(13.5),
  // },
  [theme.breakpoints.up('x5l')]: {
    paddingLeft: theme.spacing(25),
    paddingRight: theme.spacing(25),
  },
}));
