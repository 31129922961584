import { Route, Navigate } from "react-router-dom";
import { PrivateRoute } from "../Components/CustomRoutes/Partials";
// import { isEmpty } from "~/Helpers/isEmpty";
import { useUserContext } from "~/Services/Providers";
// import { getStorage, getUserPermissions, userHavePermission } from "~/Helpers";
// import EmptyPage from '~/Components/EmptyPage'
// import { AuthRoute, PrivateRoute } from "../Components/CustomRoutes/Partials";
export const PrivateRouterRenderer = (routArray) =>
  routArray.map((RouteObj) => (
    <Route
      {...RouteObj}
      element={
        <RouteObj.element />
      }
    />
    // <PrivateRoute key={index} {...RouteObj} />
  ));

export const AuthRouterRenderer = (routArray) =>
  routArray.map((RouteObj) => (
    <Route {...RouteObj} element={<RouteObj.element />} />
  ));

export const PublicRouterRenderer = (routArray) =>
  routArray.map((RouteObj) => (
    <Route {...RouteObj} element={<RouteObj.element />} />
  ));

export const RoutesRenderer = (routArray, CustomRoute) => {
  return routArray.map((RouteObj, index) => {
    return <Route key={index} element={<CustomRoute key={index} {...RouteObj} />}>
      <Route {...RouteObj} element={<RouteObj.element />} />
    </Route>
  })
};

// import { Route } from "react-router-dom";
// import Layout from "../Components/Layout";

// export const PrivateRouterRenderer = (routArray) =>
//   // <Layout>
//   routArray.map((RouteObj) => (
//     <Route
//       {...RouteObj}
//       element={
//         // <Layout>
//         <RouteObj.element />
//         // {/* // </Layout> */}
//       }
//     />
//   ));
//   // {/* </Layout> */}

// export const AuthRouterRenderer = (routArray) =>
//   routArray.map((RouteObj) => (
//     <Route {...RouteObj} element={<RouteObj.element />} />
//   ));

// export const PublicRouterRenderer = (routArray) =>
//   // <Layout>
//   routArray.map((RouteObj) => (
//     <Route
//       {...RouteObj}
//       element={
//         // <Layout>
//         <RouteObj.element />
//         // </Layout>s
//       }
//     />
//   ));
//   // </Layout>

// export const RoutesRenderer = (routArray) =>
//   routArray.map((RouteObj) => (
//     <Route {...RouteObj} element={<RouteObj.element />} />
//   ));
