import { styled } from "@mui/material/styles";
import { Button, AppBar, Box, Toolbar } from '@mui/material'
import { makeStyles } from '@mui/styles';

export const CustomBox = styled(Box)(({ theme }) => ({
    flexGrow: 1,
}));

export const CustomToolbar = styled(Toolbar)(({ theme }) => ({
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
        paddingLeft: '48px',
        paddingRight: '48px',
    },
    [theme.breakpoints.up('x5l')]: {
        paddingLeft: theme.spacing(25),
        paddingRight: theme.spacing(25),
    },
}));

export const CustomButton = styled(Button)(({ theme, CustomStyle }) => ({
    padding: '6px 24px',
    borderRadius: '8px',
    backgroundColor: !!CustomStyle?.backgroundColor ? CustomStyle?.backgroundColor : '',
    boxShadow: !!CustomStyle?.boxShadow ? CustomStyle?.boxShadow : '',
    [theme.breakpoints.up("ss")]: {
        fontSize: theme.fontSizeSM,
    },
    [theme.breakpoints.up("md")]: {
        fontSize: theme.fontSizeMD,
    },
    [theme.breakpoints.up("x3l")]: {
        fontSize: theme.fontSizeLG2,
    },
    [theme.breakpoints.up("x5l")]: {
        fontSize: theme.fontSizeXL,
    },
}));

export const CustomAppBar = styled(AppBar)(({ theme }) => ({
    // display: 'inline-flex',
    // padding: '24px 48px',
    // flexDirection: 'column',
    // alignItems: 'flex-start',
    // gap: '10px',
    // // position: 'static',
    boxShadow: 'none',
    borderBottom: ' 1px solid #E0E0E0',
    position: 'fixed',
    backgroundColor: '#fff',
    color: '#000'
}));

const useStyles = makeStyles((theme) => ({
    parentDiv: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.down('xlDown')]: {
            paddingBottom: '10px'
        }
    },
    logoChild: {
        flexBasis: '15%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        display: 'flex',
        paddingTop: '11px',
        [theme.breakpoints.down('xlDown')]: {
            flexBasis: '100%',
            justifyContent: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            // display: 'none'
            justifyContent: 'flex-start',
            flexBasis: '75%'
        },
        [theme.breakpoints.up("x5l")]: {
            flexBasis: '10%'
        },
    },
    moreChild: {
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            flexBasis: '20%',
            textAlign: 'end'
        }
    },
    linksChilds: {
        flexBasis: '63%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // gap: theme.spacing(4),
        [theme.breakpoints.down('xlDown')]: {
            flexBasis: '90%',
        },
        [theme.breakpoints.down('lg')]: {
            flexBasis: '90%',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up("x5l")]: {
            gap: '15px'
        },
    },
    subscriptionChild: {
        flexBasis: '5%',
        display: 'flex',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        // [theme.breakpoints.down('xlDown')]: {
        //     display: 'none'
        // }

    }
}))

export default useStyles