import React from 'react'
import { useQuery, useMutation, useQueryClient, queryCache } from '@tanstack/react-query'
import { useSnackbar } from 'notistack';
import api from "~/Services/Api";
import { changeUserToInit, changeUser, reset } from "~/store/app/User";
import { useSelector, useDispatch } from "react-redux";

export const useGetRequest = ({
    url,
    queryKey,
    dataForApi,
    headers,
    enabled = true,
    retry = 0,
    type = "*",
    fileName
}) => {
    const queryClient = useQueryClient()

    const user = useSelector((state) => state?.app?.user)
    const dispatch = useDispatch()

    const { enqueueSnackbar } = useSnackbar();
    const handleClickVariant = (data, variant) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(data, { variant });
    };

    let { data, error, refetch, isLoading, isPending, isRefetching, isError } = useQuery({
        queryKey: !dataForApi?.id ? [queryKey] : [queryKey, dataForApi?.id],
        // queryKey: [queryKey, dataForApi?.id],
        // queryKey: [queryKey, dataForApi?.params.length, dataForApi?.params.start],
        // z.replace("{","").replace(/.$/,"")
        queryFn: async () => {
            let Api = url.split('.')
            return await api[Api[0]][Api[1]](dataForApi, headers = {
                Authorization: user?.token,
                ...headers
            })
                .then(response => {
                    return response?.data
                })
        },
        // enabled: !!dataForApi?.id,
        enabled: enabled,
        retry: retry,
        keepPreviousData: true,
        refetchOnWindowFocus: false
    })
    // console.log('error,isError', error, isError)
    if (!!error?.response?.data?.code) {
        if (error?.response?.data?.code === "E004") {
            queryClient.clear();
            dispatch(
                changeUserToInit()
            )
        }
    }

    return { data, error, refetch, isLoading, isPending, isRefetching, isError }
}

export const useGetByIdRequest = ({
    url,
    queryKey,
    dataForApi,
    headers,
    enabled = true,
    retry = 0
}) => {
    const queryClient = useQueryClient()

    const user = useSelector((state) => state?.app?.user)
    const dispatch = useDispatch()

    const { enqueueSnackbar } = useSnackbar();
    const handleClickVariant = (data, variant) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(data, { variant });
    };

    let { data, error, refetch, isLoading, isPending, isRefetching, isError } = useQuery({
        queryKey: [queryKey, dataForApi?.id],
        queryFn: async () => {
            let Api = url.split('.')
            return await api[Api[0]][Api[1]](dataForApi, headers = {
                Authorization: user?.token,
                ...headers
            })
                .then(response => {
                    return response?.data
                })
        },
        // enabled: !!dataForApi?.id,
        enabled: !!dataForApi?.id,
        retry: retry,
        keepPreviousData: true,
        refetchOnWindowFocus: false
    })
    if (!!error?.response?.data?.code) {
        if (error?.response?.data?.code === "E004") {
            // console.log('first')
            queryClient.clear();
            dispatch(
                changeUserToInit()
            )
        }
    }

    return { data, error, refetch, isLoading, isPending, isRefetching, isError }
}

export const usePostRequest = ({
    url,
    headers,
    queryKey,
    isEdit = false,
    cashWithId = false,
    DoYouWantToAddAllTheDataEnteredToTheCache = false,
    Do_you_want_to_replace_only_variable_values = false,
    DoYouWantToReplaceTheDataInTheCacheWithNewData = false,
}) => {
    const user = useSelector((state) => state?.app?.user)
    const { enqueueSnackbar } = useSnackbar();
    const handleClickVariant = (data, variant) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(data, { variant });
    };

    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data) => {
            let Api = url.split('.')
            return api[Api[0]][Api[1]](data, headers = {
                Authorization: user?.token,
                ...headers
            })
        },
        onMutate: async (newData) => {
            await queryClient.cancelQueries({ queryKey: !!cashWithId ? [queryKey, newData.idForCash] : [queryKey] })
            const previousHeroData = queryClient.getQueryData(!!cashWithId ? [queryKey, newData.idForCash] : [queryKey])
            if (!!queryKey) {
                if (!!DoYouWantToAddAllTheDataEnteredToTheCache) {
                    if (!!isEdit) {
                        if (!!cashWithId) {
                            let updatedValue = [...previousHeroData?.data];
                            const result = updatedValue.map((item) => !!newData.body.id ? item.id === newData.body.id ? newData.body : item : item.id === newData.id ? newData.body : item);
                            queryClient.setQueryData([queryKey, newData.id], (oldQueryData) => {
                                return {
                                    ...oldQueryData,
                                    data: result
                                }
                            });
                            return () => queryClient.setQueryData([queryKey, newData.id], previousHeroData);
                        }
                        let updatedValue = [...previousHeroData?.data];
                        // const objectUpdate = updatedValue.find((obj) => obj.id === !!newData.body ? newData.body.id : newData.params.id);
                        // Object.assign(objectUpdate, newData.body);
                        const result = updatedValue.map((item) => !!newData.body.id ? item.id === newData.body.id ? newData.body : item : item.id === newData.id ? newData.body : item);
                        // const result = updatedValue.map((item) => item.id === newData.body.id ? newData.body : item);//change row data?????????????
                        // const result = updatedValue.map((item) => item.id === !!newData.body ? newData.body.id ? newData.body : item : newData.params.id ? newData.params : item);
                        // console.log("result", result)
                        queryClient.setQueryData([queryKey], (oldQueryData) => {
                            return {
                                ...oldQueryData,
                                data: result
                            }
                        });
                        return () => queryClient.setQueryData([queryKey], previousHeroData);
                    } else {
                        const updatedValue = [...previousHeroData.data];
                        const margeData = [newData?.body, ...updatedValue]
                        queryClient.setQueryData([queryKey], (oldQueryData) => {
                            return {
                                ...oldQueryData,
                                data: margeData
                            }
                        });
                        return () => queryClient.setQueryData([queryKey], previousHeroData);
                    }
                }
            }
            return { previousHeroData, newData }
        },
        onSettled: (data, error, variables, context) => {
            !!queryKey &&
                !!data?.statusText && !!DoYouWantToReplaceTheDataInTheCacheWithNewData && queryClient.setQueryData([queryKey], (oldQueryData) => {
                    if (!!oldQueryData) {
                        const newKeyResponseData = Object.entries(data?.data?.data).reduce((acc, [key, value]) => ({
                            ...acc,
                            ...(key === "admin" ? { ["InsuranceCompanies"]: value } : { [key]: value }),
                        }), {});

                        const MergeObjectsAndReplaceTheValueOfSimilarKeys = (...objects) => {
                            const MergeObjects = objects.map(object => JSON.parse(JSON.stringify(object)));
                            return MergeObjects.reduce((merged, current) => ({ ...current, ...merged }), {});
                        }

                        const newCashData = MergeObjectsAndReplaceTheValueOfSimilarKeys(data?.data, oldQueryData);
                        queryClient.invalidateQueries({ queryKey: [queryKey] })
                        return {
                            // ...data?.data?.data
                            ...newCashData
                        }
                    } else {
                        queryClient.invalidateQueries({ queryKey: [queryKey] })
                        return {
                            ...data?.data
                            // ...newCashData
                        }
                    }
                })
            // !!data?.statusText && !!Do_you_want_to_replace_only_variable_values &&
            if (!!queryKey) {
                if (!!data?.statusText) {
                    if (!!Do_you_want_to_replace_only_variable_values) {
                        const previousHeroData = queryClient.getQueryData([queryKey])
                        let updatedValue = [...previousHeroData.data];
                        const result = updatedValue.map((item) => item.id === variables.params.id ? data.data.data : item);
                        queryClient.setQueryData([queryKey], (oldQueryData) => {
                            if (!!oldQueryData) {
                                return {
                                    ...oldQueryData,
                                    data: result

                                }
                            } else {
                                return {
                                    ...data?.data
                                }
                            }
                        });
                        queryClient.invalidateQueries({ queryKey: [queryKey] })
                        return () => queryClient.setQueryData([queryKey], previousHeroData);
                    }
                }
            }

            // queryClient.setQueryData([queryKey], (oldQueryData) => {
            //     if (!!oldQueryData) {
            //         // const MergeObjectsAndReplaceTheValueOfSimilarKeys = (...objects) => {
            //         //     const MergeObjects = objects.map(object => JSON.parse(JSON.stringify(object)));
            //         //     return MergeObjects.reduce((merged, current) => ({ ...current, ...merged }), {});
            //         // }

            //         // // const newCashData = oldQueryData.data.map(i => {
            //         // //     if (i.id === context.params.id) {
            //         // //         return data.data.data;
            //         // //     } else {
            //         // //         return i;
            //         // //     }
            //         // // })
            //         // const newCashData = MergeObjectsAndReplaceTheValueOfSimilarKeys(data?.data, oldQueryData);
            //         // console.log('newCashData', newCashData, oldQueryData, data.data)
            //         // return {
            //         //     // ...data?.data?.data
            //         //     ...newCashData
            //         // }
            //         let updatedValue = [...oldQueryData.data];
            //         const result = updatedValue.map((item) => item.id === context.params.id ? data.data.data : item);
            //         console.log('first', updatedValue, result, data.data.data)
            //         return {
            //             ...oldQueryData,
            //             data: result

            //         }
            //     } else {
            //         return {
            //             ...data?.data
            //             // ...newCashData
            //         }
            //     }
            // })
            !data && !!error?.response && handleClickVariant(`${error?.response?.data?.message}`, 'warning')
            !data && !error?.response && handleClickVariant(`${error?.message}`, 'error')
            !!data?.statusText && !!data?.data?.message && handleClickVariant(data?.data?.message, 'info')
            !!data?.statusText && !data?.data?.message && handleClickVariant("operation accomplished successfully", 'info')
            if (!!queryKey) {
                return queryClient.invalidateQueries({ queryKey: !!cashWithId ? [queryKey, variables.idForCash] : [queryKey] })
            }
            // return queryClient.invalidateQueries({ queryKey: !!cashWithId ? [queryKey, variables.idForCash] : [queryKey], options: { exact: true, refetchType: 'all' } })
        }
    })
}

export const useDeleteRequest = ({
    url,
    queryKey,
    headers,
    DoYouWantToAddAllTheDataEnteredToTheCache = true,
    DoYouWantToReplaceTheDataInTheCacheWithNewData = false
}) => {
    const user = useSelector((state) => state?.app?.user)
    const { enqueueSnackbar } = useSnackbar();
    const handleClickVariant = (data, variant) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(data, { variant });
    };

    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data) => {
            let Api = url.split('.')
            return api[Api[0]][Api[1]](data, headers = {
                Authorization: user?.token,
                ...headers
            })
        },
        onMutate: async (newData) => {
            await queryClient.cancelQueries([queryKey])
            const previousHeroData = queryClient.getQueryData([queryKey])
            if (!!queryKey) {
                if (!!DoYouWantToAddAllTheDataEnteredToTheCache) {
                    const updatedValue = [...previousHeroData.data];
                    const removeDeleted = updatedValue.filter(
                        eachValue => eachValue.id !== newData.id
                    );
                    queryClient.setQueryData([queryKey], (oldQueryData) => {
                        return {
                            ...oldQueryData,
                            data: removeDeleted
                        }
                    });
                    // console.log("::", updatedValue, removeDeleted, newData.id)
                    return () => queryClient.setQueryData([queryKey], previousHeroData);
                }
            }
            return { previousHeroData };

        },
        onSettled: (data, error, newData) => {
            // queryClient.removeQueries([queryKey, newData.id]);
            // queryClient.refetchQueries(queryKey);

            !data && !!error?.response && handleClickVariant(`${error?.response?.data?.message}`, 'warning')
            !data && !error?.response && handleClickVariant(`${error?.message}`, 'error')
            !!data?.statusText && !!data?.data?.message && handleClickVariant(data?.data?.message, 'info')
            !!data?.statusText && !data?.data?.message && handleClickVariant("operation accomplished successfully", 'info')
            // console.log('data', data, error, data?.response?.data?.message)
            // queryClient.refetchQueries({ queryKey: [queryKey] });
            queryClient.invalidateQueries({ queryKey: [queryKey] })
        }
    })
}

// export const useDeleteFn = () => {
//     return useMutation(
//         (data) => console.log('data', data),
//         {
//             onMutate: editedValue => {
//                 const previousValue = queryCache.getQueryData("super-herose");
//                 const updatedValue = [...previousValue];
//                 const removeDeleted = updatedValue.filter(
//                     eachValue => eachValue.id !== editedValue.id
//                 );

//                 queryCache.setQueryData("super-herose", removeDeleted);

//                 return () => queryCache.setQueryData("super-herose", previousValue);
//             },

//             onError: (error, editedValue, rollback) => {
//                 rollback();
//             },

//             onSettled: (data, error, editedValue) => {
//                 queryCache.removeQueries(["super-herose", editedValue.id]);
//                 queryCache.refetchQueries("super-herose");
//             }
//         }
//     );
// };

// export const useDeleteSuperHeroData = () => {
//     // const { enqueueSnackbar } = useSnackbar();
//     // const handleClickVariant = (data, variant) => {
//     //     // variant could be success, error, warning, info, or default
//     //     enqueueSnackbar(data, { variant });
//     // };
//     const queryClient = useQueryClient()
//     return useMutation((data) => axios.delete(`http://localhost:4000/superherose/${1}`), {
//         onMutate: async (newHero) => {
//             console.log('newHero', newHero)
//             const previousValue = queryClient.getQueryData("super-herose");
//             const updatedValue = [...previousValue.data];
//             const removeDeleted = updatedValue.filter(
//                 eachValue => eachValue.id !== newHero.id
//             );

//             queryClient.setQueryData("super-herose", removeDeleted);

//             return () => queryClient.setQueryData("super-herose", previousValue);
//         },
//         onError: (error, editedValue, rollback) => {
//             rollback();
//             // handleClickVariant(error.message, 'error')
//         },

//         onSettled: (data, error, editedValue) => {
//             // !error && handleClickVariant("ok...", 'success')
//             queryClient.removeQueries(["super-herose", editedValue.id]);
//             queryClient.refetchQueries("super-herose");
//         }
//     })
// }