import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "~Services/Helpers/isEmpty";

export const userHavePermission = (userPermission, ...permissions) => {
    if (permissions.includes('public')) return true;
    return permissions.some((permission) => userPermission?.name === permission
        // userPermission.some((userPermission) => userPermission?.name === permission)
    );
};

// export const getUserPermissions = (roles = []) => {
export const getUserPermissions = (roles) => {
    let permissions = [];
    const rolesPermission = [];
    roles?.permissions?.forEach((permission) => {
        rolesPermission.push(permission?.name);
    });
    permissions = [...permissions, ...rolesPermission];
    return permissions;
};


export const UserHavePermissionToUseIt = (permission, type = "all") => {
    const user = useSelector((state) => state.app.user)
    const userPermissions = !isEmpty(user?.role)
        ? getUserPermissions(user?.role)
        : [];

    let componentPermissions = Array.isArray(permission)
        ? permission
        : [permission];

    if (type === "action") if (user?.role?.name === "read_only") return false;
    if (permission === "public") return true;
    if (userPermissions.includes("All")) return true;
    if (user?.role?.name === "superadministrator") return true;
    return componentPermissions.some(permissions => userPermissions.includes(permissions))
    // return userHavePermission(userPermissions, ...componentPermissions)
}