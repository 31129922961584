import React, { useEffect } from 'react';
import darkIcon from '~/Assets/PROMED png logo 3.png';
import logoDark from "~Assets/PROMED png logo 3.png";
import './index.css';
import ItemLoader from './Partial/ItemLoader';
import useStyles from './styles';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import img from "~/Assets/pros2.jpg"

// import { useGetRequest } from "~Hooks/Queries";
// import { changeUser } from "~store/app/User";
// import { useSelector, useDispatch } from "react-redux";
// import { AppCrashedComponent } from '../../ErrorBoundary/Partials';

// import {
//   useAppContext,
// } from "~/Services/Providers";

const GeneralLoader = () => {
  const classes = useStyles();
  // const { appContextObj, setAppContext } = useAppContext();
  // const dispatch = useDispatch()
  // const user = useSelector((state) => state?.app?.user)

  // const {
  //   data: responseGetRequest,
  //   error,
  //   refetch,
  //   isLoading,
  //   isRefetching
  // } = useGetRequest({
  //   url: "user.privileges",
  //   queryKey: 'user-privileges',
  //   enabled: false
  //   // headers: {
  //   //   Authorization: user?.token
  //   // }
  // });
  // useEffect(() => {
  //   if (!!user?.token) refetch()
  // }, [user?.token])


  // useEffect(() => {
  //   !!responseGetRequest?.data && dispatch(
  //     changeUser(
  //       {
  //         role: responseGetRequest?.data[0]
  //         // role: []
  //       }
  //     )
  //   )
  // }, [responseGetRequest])

  // if (error) return <AppCrashedComponent />
  // <div style={{ width: '100vw', height: '100vh', backgroundImage: `url("${img}")`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
  // </div >
  return (
    <div id="loader" className={classes.loader} style={{ width: '100vw', height: '100vh', backgroundImage: `url("${img}")`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="loader-wrapper">
        <div className="loader-container" style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center' }}>
          <div>
            <img
              className="logo-loader"
              // src={appContextObj.theme === 'light' ? darkIcon : lightIcon}
              // src={appContextObj.theme === 'light' ? darkIcon : logoDark}
              src={logoDark}
            />
          </div>
          <ItemLoader
            classNameContainer={'cs-loader'}
            classNameItem={'cs-loader-inner'}
          />
          {/* <Box sx={{ width: '50%' }}>
            <LinearProgress sx={{
              '&.MuiLinearProgress-colorPrimary': {
                backgroundColor: 'transparent'
              },
              "& .MuiLinearProgress-bar": {
                backgroundColor: `#FB882A`
              }
            }} />
          </Box> */}
        </div>
      </div>
    </div>
  );
};

export default GeneralLoader;
