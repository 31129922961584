import React, { useState, createContext, useContext } from 'react';

export const NOTIFICATION_INITIAL_STATE = {
  open: false,
  vertical: 'bottom',
  horizontal: 'left',
  severity: 'info',
  message: 'empty',
};

// initializing context
export const NotificationContext = createContext(NOTIFICATION_INITIAL_STATE);

// setting a helper function to use context
export const useNotification = () => useContext(NotificationContext);

export function NotificationContextProvider({ children }) {
  const [Notification, setNotification] = useState(NOTIFICATION_INITIAL_STATE);
  return (
    <NotificationContext.Provider value={{ Notification, setNotification }}>
      {children}
    </NotificationContext.Provider>
  );
}
