import React, { lazy } from "react";

// export const publicRoutes = [
//   { path: "/", element: lazy(() => import("../Screens/Public")), },
//   { path: "*", element: lazy(() => import("../Screens/EmptyPage")), },
// ];

export const authRoutes = [
  {
    path: "/",
    element: lazy(() => import("../Screens/Auth/Login")),
    permission: 'public'
  },
  // {
  //   path: "*",
  //   element: lazy(() => import("../Screens/EmptyPage")),
  //   permission: 'public'
  // },
];

export const privateRoutes = [
  {
    path: "/doctors",
    element: lazy(() => import("../Screens/Home")),
    permission: ['superadministrator', 'read_only'],
    breadcrumb: "doctors"
  },
  {
    path: "/top-doctors",
    element: lazy(() => import("../Screens/TopDoctors")),
    permission: ['superadministrator', 'read_only'],
    breadcrumb: "top doctors"
  },
  {
    path: "/beauty-center",
    element: lazy(() => import("../Screens/BeautyCenters")),
    permission: 'public',
    breadcrumb: "beauty center"
  },
  {
    path: "/beauty-center/:id",
    element: lazy(() => import("../Screens/BeautyCenter")),
    permission: 'public',
    breadcrumb: ":id"
  },
  {
    path: "/finance-department",
    element: lazy(() => import("../Screens/FinanceDepartment")),
    permission: ['superadministrator', 'read_only']
  },
  {
    path: "/logs",
    element: lazy(() => import("../Screens/Logs")),
    permission: ['superadministrator', 'read_only']
  },
  {
    path: "/doctors/:id",
    element: lazy(() => import("../Screens/DoctorDetials")),
    permission: ['superadministrator', 'read_only']
  },
  {
    path: "/feedbacks",
    element: lazy(() => import("../Screens/Feedbacks")),
    permission: ['superadministrator', 'read_only']
  },
  {
    path: "/packages",
    element: lazy(() => import("../Screens/Packages")),
    permission: ['superadministrator', 'read_only']
  },
  {
    path: "/notifications",
    element: lazy(() => import("../Screens/Notifications")),
    permission: ['superadministrator', 'read_only']
  },
  {
    path: "/hospital",
    element: lazy(() => import("../Screens/Hospital")),
    permission: ['superadministrator', 'read_only']
  },
  {
    path: "/patient",
    element: lazy(() => import("../Screens/Patient")),
    permission: ['superadministrator', 'read_only']
  },
  {
    path: "/consultings",
    element: lazy(() => import("../Screens/Consultings")),
    permission: ['superadministrator', 'read_only']
  },
  {
    path: "/privacy-police",
    element: lazy(() => import("../Screens/PrivacyPolice")),
    permission: ['superadministrator', 'read_only']
  },
  {
    path: "/error/403",
    element: lazy(() => import("../Screens/Error403")),
    permission: ['public']
  },
  {
    path: "*",
    element: lazy(() => import("../Screens/EmptyPage")),
    permission: ['superadministrator', 'read_only']
  },
];
