import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { userHavePermission } from "~Services/Helpers";
import { isEmpty } from "~Services/Helpers/isEmpty";
import { useGetRequest } from "../../../../Hooks/Queries";
import { changeUser } from "../../../../store/app/User";
import GeneralLoader from "../../../UI/GeneralLoader";
import { AppCrashedComponent } from "../../../ErrorBoundary/Partials";

function PrivateRoute(rest) {
  const user = useSelector((state) => state?.app?.user)
  const dispatch = useDispatch()

  const {
    data: responseGetRequest,
    error,
    refetch,
    isError,
    isLoading,
    isRefetching
  } = useGetRequest({
    url: "user.privileges",
    queryKey: 'user-privileges',
    // enabled: false
    // headers: {
    //   Authorization: user?.token
    // }
  });

  useEffect(() => {
    if (!!responseGetRequest?.data) {
      dispatch(
        changeUser(
          {
            role: responseGetRequest?.data[0]
          }
        )
      )
    }
  }, [responseGetRequest])

  const userpermissions = !isEmpty(user?.role)
    ? user?.role
    : [];
  let itemPermissions = Array.isArray(rest?.permission)
    ? rest?.permission
    : [rest?.permission];

  if (!user?.token) return <Navigate to="/" exact />
  if ((!!isLoading || !!isRefetching) && !!isEmpty(user?.role)) {
    return <GeneralLoader />
  } else if (!!error && !!isEmpty(user?.role)) {
    return <AppCrashedComponent />
  } else {
    return !!user?.token ? (
      !isEmpty(user?.role) ? (
        userHavePermission(!!error ? user?.role : user?.role, ...itemPermissions) ? (
          <Outlet />
        ) : (
          <Navigate to="/error/403" />
        )) : <AppCrashedComponent />
    ) : (
      <Navigate to="/" exact />
    );
  }
}

export default PrivateRoute;
