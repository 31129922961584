import { styled } from "@mui/material/styles";
import { } from '@mui/material'

export const CustomFooter = styled('div')(({ theme }) => ({
    display: 'flex',
    padding: '24PX 48PX',
    borderTop: '1px solid #E0E0E0',
    [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        padding: '24PX 24PX',
    },
}));