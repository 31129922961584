// import { Breadcrumbs, Typography, Button } from '@material-ui/core'
// import { ArrowBack  } from '@material-ui/icons'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { UpperCaseFirst } from '~/Services/Helpers'
import useStyles from './styles'
import { Breadcrumbs, Button, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useBreadcrumbs from "use-react-router-breadcrumbs";

const SubHeader = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const locationArr = window.location.pathname.split('/');
    const Routes = [
        {
            path: "/",
            breadcrumb: ""
        },
        {
            path: "/doctors",
            breadcrumb: "doctors"
        },
        {
            path: "/beauty-center",
            breadcrumb: "beauty center"
        },
        {
            path: "/finance-department",
            breadcrumb: "finance"
        },
        {
            path: "/logs",
            breadcrumb: "logs"
        },
        {
            path: "/feedbacks",
            breadcrumb: "feedbacks"
        },
        {
            path: "/packages",
            breadcrumb: "packages"
        },
        {
            path: "/notifications",
            breadcrumb: "notifications"
        },
        {
            path: "/hospital",
            breadcrumb: "hospital"
        },
        {
            path: "/patient",
            breadcrumb: "patient"
        },
        {
            path: "/consultings",
            breadcrumb: "consultings"
        },
        {
            path: "/privacy-police",
            breadcrumb: "privacy police"
        },
        {
            path: "/error/403",
            breadcrumb: "403"
        },
        {
            path: "*",
            breadcrumb: "404"
        },
    ]
    const breadcrumbs = useBreadcrumbs(Routes);
    let lastPahe = breadcrumbs[breadcrumbs.length - 2].breadcrumb
    const forward = (isLast, index) => {
        if (!isLast)
            navigate(-(index - 1));
    }

    return (
        <div className={classes.root}>
            <Button
                startIcon={<ArrowBackIcon sx={{ margin: 0 }} />}
                onClick={() => navigate(-1)}
                sx={{
                    minWidth: 0, height: '30px', width: '30px', '& .MuiButton-startIcon': {
                        margin: 0
                    }
                }}
            // size='small'
            >
                {/* {
                    locationArr.length <= 2 && lastPahe + " -"
                } */}
            </Button>

            <Breadcrumbs maxItems={4} aria-label="breadcrumb">
                <p onClick={() => locationArr[1] != locationArr[locationArr.length - 1] && navigate(locationArr[1])}
                    style={{
                        textDecoration: locationArr[1] != locationArr[locationArr.length - 1] ? "underline" : "none",
                        cursor: locationArr[1] != locationArr[locationArr.length - 1] ? "pointer" : "auto"
                    }}
                >
                    {UpperCaseFirst(locationArr[1])}
                </p>

                {
                    locationArr.length > 2 && locationArr.map((path, index) => {
                        if (index > 1) {
                            // const isLast = path === locationArr[locationArr.length - 1];
                            const isLast = path === locationArr[locationArr.length - 1];
                            return (
                                <p style={{ textDecoration: !isLast ? 'underline' : 'none', cursor: !isLast ? 'pointer' : 'auto' }} onClick={() => forward(isLast, index)} color="inherit" key={index} className={classes.breadCrumb}>
                                    {UpperCaseFirst(path)}
                                </p>
                            )
                        }
                    })
                }



            </Breadcrumbs>
        </div>
    )
}

export default SubHeader
