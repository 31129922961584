import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  loader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? '#fff'
        : theme.palette.secondary.main,
  },
}));

export default useStyles;