import React from 'react';
import useStyles from './styles';
import Box from '@mui/material/Box';
import Logo from '~Assets/Image.png';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import iconsMenuo from '~Assets/PROMED png logo 3.png';
import ListItemButton from '@mui/material/ListItemButton';
import { changeUserToInit } from '../../../../store/app/User';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { CustomAppBar, CustomToolbar, CustomBox } from './styles';

const Header = () => {
    const classes = useStyles();
    const dispatch = useDispatch()

    const links = [
        {
            title: 'Doctors',
            to: 'doctors',
        },
        {
            title: 'Hospital',
            to: 'hospital',
        },
        {
            title: 'patient',
            to: 'patient',
        },
        {
            title: 'Packages',
            to: 'packages',
        },
        {
            title: 'Privacy Police',
            to: 'privacy-police',
        }
    ]
    const menulinks = [
        {
            title: 'Doctors',
            to: 'doctors',
        },
        {
            title: 'Top Doctors',
            to: 'top-doctors',
        },
        {
            title: 'Finance department',
            to: 'finance-department',
        },
        {
            title: 'Hospital',
            to: 'hospital',
        },
        {
            title: 'patient',
            to: 'patient',
        },
        {
            title: 'Consultings',
            to: 'consultings',
        },
        {
            title: 'Notifications',
            to: 'notifications',
        },
        {
            title: 'Feedbacks',
            to: 'feedbacks',
        },
        {
            title: 'Packages',
            to: 'packages',
        },
        {
            title: 'Logs',
            to: 'logs',
        },
        {
            title: 'Privacy Police',
            to: 'privacy-police',
        },
        {
            title: 'Beauty Center',
            to: 'beauty-center',
        }
    ]
    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {menulinks.map((i, index) => (
                    <ListItem key={index}>
                        <ListItemButton>
                            <ListItemIcon>
                                <img src={iconsMenuo} style={{ width: '20px' }} />
                            </ListItemIcon>
                            {/* <ListItemText primary={i.title} /> */}
                            <NavLink key={i.title} to={i.to} onClick={toggleDrawer('right', false)}
                                style={({ isActive, isPending, isTransitioning }) => {
                                    return {
                                        fontWeight: isActive ? "bold" : "",
                                        color: isPending ? "red" : "#0094D9",
                                        viewTransitionName: isTransitioning ? "slide" : "",
                                        textDecoration: 'none'
                                    };
                                }}>{i.title}</NavLink>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    return (
        <CustomBox>
            <CustomAppBar>
                <CustomToolbar>
                    <div
                        className={classes.parentDiv}
                    >
                        <div
                            className={classes.logoChild}
                        >
                            <img src={Logo} />
                        </div>
                        <div
                            className={classes.linksChilds}
                        >
                            {
                                links.map(i => (
                                    <NavLink key={i.title} to={i.to}
                                        style={({ isActive, isPending, isTransitioning }) => {
                                            return {
                                                fontWeight: isActive ? "bold" : "",
                                                color: isPending ? "red" : "#0094D9",
                                                viewTransitionName: isTransitioning ? "slide" : "",
                                                textDecoration: 'none'
                                            };
                                        }}>{i.title}</NavLink>
                                ))
                            }
                            <NavLink onClick={() => dispatch(changeUserToInit())} style={{ cursor: 'pointer', textDecoration: 'none' }}>LoGout</NavLink>
                        </div>
                        <div
                            className={classes.subscriptionChild}
                            onClick={toggleDrawer('right', true)}
                        >
                            {/* <CustomButton
                                variant='text'
                                sx={{
                                    textDecoration: 'underline',
                                    textUnderlineOffset: '5px'
                                }}
                            >
                                subscription
                            </CustomButton> */}
                            <MenuOutlinedIcon color="primary" />
                        </div>
                        {/* <div
                            className={classes.moreChild}
                            onClick={toggleDrawer('right', true)}
                        >
                            <MenuOutlinedIcon />
                        </div> */}
                        <Drawer
                            anchor={'right'}
                            open={state['right']}
                            onClose={toggleDrawer('right', false)}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderTopLeftRadius: '20px',
                                    borderBottomLeftRadius: '20px',
                                    direction: 'rtl'
                                }
                            }}
                        >
                            {list('right')}
                        </Drawer>
                    </div>
                </CustomToolbar>
            </CustomAppBar>
        </CustomBox >
    )
}

export default Header
